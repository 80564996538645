<template>
    <div>
        <div>
            <div class="content-header container-fluid bg-white">
                <div class="row">
                    <div class="col-sm-4">
                        <h4><i class="ti-files"> </i> 检查巡检 | 检查记录</h4>
                    </div>
                </div>
            </div>
            <div class="content">
                <div class="row">
                    <div class="col-md-12">
                        <el-button type="primary" @click="rowEdit()">新增记录</el-button>
                        <el-button type="success" @click="exportExcel()">导出Excel</el-button>
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="w-block" style="padding: 2px;">
                            <!-- <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px">
                                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
                            </el-input> -->
                            <el-select v-model="grid.sea.TYPE_ID" @change="filter" clearable placeholder="请选择类型">
                                <el-option v-for="item in typeList" :key="item.Key" :value="item.Key"
                                    :label="item.Value" />
                            </el-select>
                            <el-date-picker v-model="grid.sea.START_DT" value-format="yyyy-MM-dd" @change="filter"
                                type="date" placeholder="选择开始日期">
                            </el-date-picker>
                            <el-date-picker v-model="grid.sea.END_DT" value-format="yyyy-MM-dd" @change="filter"
                                type="date" placeholder="选择结束日期">
                            </el-date-picker>
                        </div>
                        <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total"
                            :page-index="grid.sea.PAGE_INDEX+1" :page-size="grid.sea.PAGE_SIZE"
                            @page-changed="loadPage">
                            <el-table-column prop="TYPE_NAME" label="类型名称" width="150" align="center" />
                            <el-table-column prop="DATE" label="检查日期" width="100" align="center" />
                            <el-table-column prop="LOCATION" label="检查位置" width="100" align="center" />
                            <el-table-column prop="PROBLEM" label="存在问题" width="200" align="center" />
                            <el-table-column prop="STATUS_TEXT" label="状态" width="100" align="center">
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.STATUS==0" type="info"> {{scope.row.STATUS_TEXT}}</el-tag>
                                    <el-tag v-else-if="scope.row.STATUS==1" type="danger">
                                        {{scope.row.STATUS_TEXT}}</el-tag>
                                    <el-tag v-else-if="scope.row.STATUS==2" type="success">
                                        {{scope.row.STATUS_TEXT}}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="CREATED_NAME" label="检查人" width="100" align="center" />
                            <el-table-column label="签名" width="150" align="center">
                                <template slot-scope="scope">
                                    <el-image style="height: 40px;margin-top: 5px;" v-if="scope.row.SIGN_KV"
                                        :src="scope.row.SIGN_KV.Value"></el-image>
                                </template>

                            </el-table-column>
                            <el-table-column prop="RECTIFY_NAME" label="整改人" width="100" align="center" />
                            <el-table-column label="操作" width="200">
                                <template slot-scope="scope">

                                    <el-button type="text" icon="el-icon-edit" v-if="scope.row.STATUS!=2"
                                        @click="rowEdit(scope.row)">修改</el-button>
                                    <el-button type="text" icon="el-icon-view" v-if="scope.row.STATUS==2"
                                        @click="rowDetail(scope.row)">详情</el-button>
                                    <el-button type="text" icon="el-icon-delete"
                                        @click="rowDel(scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column />
                        </wgrid>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <Edit ref="type_edit" @on-saved="filter" :typeList="typeList" />
            <Detail ref="type_detail" />
        </div>
    </div>
</template>
<script>
    import Edit from "./edit";
    import Detail from "./detail";
    import Wgrid from "@/components/wgrid";
    import jsFileDownload from 'js-file-download'
    export default {
        name: "index",
        components: { Wgrid, Edit, Detail },
        data() {
            return {
                grid: {
                    sea: {
                        PAGE_SIZE: 12,
                        PAGE_INDEX: 0,
                    },
                    ls: [],
                    total: 0,
                    loading: false
                },
                typeList: []
            }
        },
        created() {
            this.getTypes()
            this.getList();
        },
        methods: {
            filter() {
                this.grid.sea.PAGE_INDEX = 0;
                this.grid.ls = [];
                this.getList();
            },
            loadPage(pi) {
                this.grid.sea.PAGE_INDEX = pi - 1;
                this.getList();
            },
            getList() {
                let self = this;
                this.grid.loading = true;
                this.whale.remote.getCollection({
                    url: "/api/School/INS/RecordApi/GetRecordList",
                    data: this.grid.sea,
                    completed: function (its, n) {
                        self.grid.ls = its;
                        self.grid.total = n;
                        self.grid.loading = false;
                    }
                })
            },
            rowEdit(r) {
                this.$refs.type_edit.init(r);
            },
            rowDetail(r) {
                this.$refs.type_detail.init(r);
            },
            rowDel(r) {
                this.whale.delConfirm(() => {
                    let self = this;
                    this.whale.remote.getResult({
                        url: "/api/School/INS/RecordApi/DelRecord",
                        data: { ID: r.ID },
                        completed: function () {
                            //self.filter();
                            self.grid.ls = self.grid.ls.filter(o => o.ID != r.ID)
                        }
                    })
                });
            },

            getTypes() {
                let self = this;
                this.whale.remote.getResult({
                    url: "/api/School/INS/TypeApi/GetTypeKvs",
                    completed: function (its) {
                        self.typeList = its.DATA;
                    }
                })
            },
            exportExcel() {
                let self = this;
                this.grid.loading = true;
                const formData = new FormData()
                formData.append('TYPE_ID', this.grid.sea.TYPE_ID || 0)
                formData.append('START_DT', this.grid.sea.START_DT || '')
                formData.append('END_DT', this.grid.sea.END_DT || '')
                this.whale.remote.getExport({
                    url: "/api/School/INS/RecordApi/Export",
                    data: formData,
                    completed: function (its) {
                        jsFileDownload(its, '检查巡检记录.xls')
                        self.grid.loading = false;
                    }
                })
            }
        }
    }
</script>