<template>
    <el-dialog :visible.sync="sta.show" width="750px">
        <span slot="title">查看 检查记录</span>
        <div class="row">
            <div class="col-md-12">
                <el-row>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>选择类型：</b></label>
                            <span><b>{{model.TYPE_NAME}}</b></span>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="form-group">
                            <label class="label1" style="float: left;"><b>检查内容：</b></label>
                            <el-table :data="model.ITEM_LIST" style="width: 80%;margin-left: 89px;" border
                                :header-cell-style="headerCellStyle">
                                <el-table-column prop="NAME" label="项目" align="center">
                                </el-table-column>
                                <el-table-column label="情况" align="center">
                                    <template slot-scope="scope">
                                        <el-radio v-model="scope.row.STATUS" :label="0" disabled>合格</el-radio>
                                    </template>
                                </el-table-column>
                                <el-table-column align="center">
                                    <template slot-scope="scope">
                                        <el-radio v-model="scope.row.STATUS" :label="1" disabled>整改</el-radio>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>检查位置：</b></label>
                            <span><b>{{model.LOCATION}}</b></span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>检查日期：</b></label>
                            <span><b>{{model.DATE}}</b></span>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="form-group">
                            <label class="label1"><b>存在问题：</b></label>
                            <span><b>{{model.PROBLEM}}</b></span>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>整改人：</b></label>
                            <span><b>{{model.RECTIFY_NAME}}</b></span>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="form-group">
                            <label class="label1"><b>整改时间：</b></label>
                            <span><b>{{model.RECTIFY_DATE}}</b></span>
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="sign">
                            <label class="label1"><b>签名：</b></label>
                            <el-image v-if="model.SIGN_KV" :src="model.SIGN_KV.Value" alt="" />
                        </div>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <div class="form-group" style="display: flex">
                            <label class="label1"><b>整改照片：</b></label>
                            <el-image v-for="(item,index) in model.RECTIFY_IMAGE_KVS" :src="item.Value" :key="index"
                                alt="" style="max-width: 100px;max-height: 100px; margin-right: 5px;" />
                        </div>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <div class="form-group">
                            <label class="label1"><b>整改说明：</b></label>
                            <span><b>{{model.RECTIFY_DESC}}</b></span>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div slot="footer">
            <el-button icon="el-icon-close" @click="sta.show=false">关闭</el-button>
        </div>
    </el-dialog>
</template>

<script>

    export default {
        name: "detail",
        data() {
            return {
                model: {
                    TYPE_ID: 0,
                    DATE: "",
                    LOCATION: "",
                    PROBLEM: "",
                    CONTENT_JSON: "",
                    RECTIFY_ID: 0,
                },
                user: this.whale.user.get(),
                fileList: [],
                sta: {
                    show: false,
                },

            }
        },
        created() {

        },
        methods: {
            init(model) {
                this.sta = { show: true }
                if (model != undefined && model != null) {
                    this.model = JSON.parse((JSON.stringify(model)));
                } else {
                    this.model = {};
                }
            },

            headerCellStyle({ row, column, rowIndex, columnIndex }) {
                if (row[0].level === 1) {
                    row[1].colSpan = 2;
                    row[2].colSpan = 0;
                    if (columnIndex === 2) {
                        return { display: "none" };
                    }
                }
                return {
                    color: "#333333",
                    fontWeight: "bold",
                    textAlign: "center",
                };
            },

        }
    }
</script>

<style scoped>
    .label1 {
        width: 6em;
        text-align: right;
        margin-right: 5px;
    }
</style>